import { SET_LOADING, SHOW_MESSAGE } from "./commonTypes";

const initialState = {
    loading: false,
    message: {type: 'success', hideDuration: 5000}
}

const CommonReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload.loading
            }
        case SHOW_MESSAGE:
            return {
                ...state,
                message: payload
            }
        default:
            return state
    }
}

export default CommonReducer