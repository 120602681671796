import {SET_USER_INFO, GET_LIST_USER, LOGOUT, SET_CURRENT_STORE} from "./userTypes"
import Axios from "axios"

export const getListUser = (filter) => {
    return async dispatch => {
        const response = await Axios.get('https://jsonplaceholder.typicode.com/users')
        dispatch({
            type: GET_LIST_USER,
            payload: {
                users: response.data
            }
        })
    }
}

export const setUserInfo = userInfo => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    return {
        type: SET_USER_INFO,
        payload: {
            userInfo
        }
    }
}

export const signout = () => {
    localStorage.clear();
    // localStorage.removeItem("userInfo");
    return {
        type: LOGOUT,
        payload: {}
    }
}

export const setCurrentStore = storeInfo => {
    if (storeInfo) {
        localStorage.setItem("currentStore", JSON.stringify(storeInfo));
    } else {
        localStorage.removeItem("currentStore");
    }
    return {
        type: SET_CURRENT_STORE,
        payload: {
            storeInfo
        }
    }
}
