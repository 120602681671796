import React from 'react';
import './App.css';
import './Responsive.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import Layout from './components/shared/layout';
import io from 'socket.io-client';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import SocketContext from './context/socket'
import './utils'
const socket = io(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket'], jsonp: false });
socket.connect();
socket.on('connect', () => {
  window.socketId = socket.id
  console.log('connected to socket server');
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d49639',
      contrastText: '#ffffff'
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SocketContext.Provider value={socket}>
          <Layout />
        </SocketContext.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
