import { SET_LOADING, SHOW_MESSAGE } from './commonTypes';

export const setLoading = loading => {
    return {
        type: SET_LOADING,
        payload: {loading}
    }
}

export const showMessage = (message, type = 'success', hideDuration = 5000) => {
    return {
        type: SHOW_MESSAGE,
        payload: {text: message, type, hideDuration}
    }
}
