import React, { useRef, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { HttpService } from '../../../services/httpService';

const PopupDiscount = ({order = {}, open, onClose, ...props}) => {
    const [note, setNote] = useState('')
    const radioGroupRef = useRef(null);
    const [discountType, setDiscountType] = useState('SPECIFIC_AMOUNT')
    const [discount, setDiscount] = useState(0)

    const updateOrder = async () => {
        const data = {
            status: 'FINISHED',
            discountType: discountType || 'SPECIFIC_AMOUNT',
            discount: discount || 0,
            note
        }
        await HttpService.put(`/orders/${order._id}`, data)
        hidePopupDiscount(true)
    }

    const resetDiscount = () => {
        setDiscountType('SPECIFIC_AMOUNT')
        setDiscount(0)
    }

    const hidePopupDiscount = (reload) => {
        onClose(reload)
        resetDiscount()
    }
    
    const getDiscountAmount = () => {
        if (discountType === 'SPECIFIC_AMOUNT') {
            return discount || 0
        } else if (discountType === 'PERCENT_AMOUNT') {
            return ((order.totalAmount * +discount)/100) || 0
        }
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Xác nhận giảm giá</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="Loại giảm giá"
                    name="discountType"
                    row
                    value={discountType}
                    onChange={e => setDiscountType(e.target.value)}
                >
                    <FormControlLabel value='SPECIFIC_AMOUNT' control={<Radio />} label='VNĐ' />
                    <FormControlLabel value='PERCENT_AMOUNT' control={<Radio />} label='%' />
                </RadioGroup>
                <TextField className='form-control' defaultValue={discount} type='number' label={{ SPECIFIC_AMOUNT: 'Giảm theo số tiền', PERCENT_AMOUNT: 'Giảm theo phần trăm' }[discountType]} onChange={e => setDiscount(e.target.value)} />
                <TextField className='form-control' defaultValue={note} label='Ghi chú' onChange={e => setNote(e.target.value)} />
                <div style={{marginTop: 10}}>
                    <div>Tổng trước giảm: {window.formatMoney(order.totalAmount)}</div>
                    <div>Số tiền giảm: {window.formatMoney(getDiscountAmount())}</div>
                    <div>Tổng sau giảm: <strong>{window.formatMoney(order.totalAmount - getDiscountAmount())}</strong></div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={e => updateOrder()} color="primary">
                    Ok
            </Button>
                <Button onClick={e => onClose()} color="primary">
                    Đóng
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupDiscount;