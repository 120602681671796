import React, { useEffect } from 'react';
import logo from '../../public/logo.png'
import christmas1 from '../../public/christmas1.png'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../../redux';
import { useAuth } from '../../context/auth';
import { signout } from './../../redux/user/userActions';

const Header = (props) => {
    const userInfo = useSelector(state => state.user.userInfo)
    const dispatch = useDispatch()
    const { setAuthTokens } = useAuth();

    const currentStore = useSelector(state => state.user.currentStore);

    const setUserInfoFromLocalStorage = () => {
        dispatch(setUserInfo(JSON.parse(localStorage.getItem('userInfo'))))
    }

    useEffect(() => {
        setUserInfoFromLocalStorage()
    }, [])

    const logout = () => {
        setAuthTokens(null)
        dispatch(signout())
    }

    return (
        <header id="banner">
            {/* <img className="christmas1" src={christmas1} /> */}
            <img src={logo} onClick={e => props.history.push('/')} />
            {
                userInfo && <>
                <div className="profile">
                    <div className="avatar">
                        {userInfo.nickName}
                    </div>
                    <div onClick={e => logout()} style={{cursor: 'pointer'}}>
                        Thoát
                    </div>
                </div>
                {userInfo.store && <div>Chi nhánh: {userInfo.store.name}</div>}
                {currentStore && <div>Chi nhánh: {currentStore?.name}</div>}
                </>
            }
        </header>
    );
};

export default withRouter(Header);
