import Axios from "axios"
import store from '../redux/store'
import { setLoading, showMessage } from './../redux/common/commonActions';

const getHeaders = () => {
    return {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        SocketId: window.socketId
    }
}

const buildParams = params => {
    return Object.keys(params).length ? `?${Object.keys(params).map(key => key + '=' + params[key]).join('&')}` : '';
}

const toggleLoading = loading => {
    store.dispatch(setLoading(loading))
}

const handleError = (err) => {
    toggleLoading(false)
    try {
        if (err.response.status === 401) {
            store.dispatch(showMessage('Không có quyền truy cập!', 'warning'))
            localStorage.removeItem('tokens')
            window.location.href = '/login'
        } else if (err.response.status === 409) {
            store.dispatch(showMessage(err.response.data.message, 'warning'))
            return false
        }
        else {
            return err.response.data
        }
    } catch (e) {
        
    }
}

export const HttpService = {
    get: async (path, queryParams = {}) => {
        toggleLoading(true)
        try {
            const options = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}${path}${buildParams(queryParams)}`,
                headers: getHeaders(),
            }
            if (queryParams.export) {
                options.responseType = 'blob'
            }

            const response = await Axios(options)
            toggleLoading(false)

            if (queryParams.export) {
                const type = response.headers['content-type']
                const filename = response.headers['filename']
                const blob = new Blob([response.data])
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${filename}.xlsx`;
                a.click();
                return null
              }
        
            return response.data
        } catch (err) {
            return handleError(err)
        }
    },
    post: async (path, data) => {
        toggleLoading(true)
        try {
            const response = await Axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}${path}`,
                data: data,
                headers: getHeaders()
            })
            toggleLoading(false)
            return response.data
        } catch (err) {
            return handleError(err)
        }
    },
    put: async (path, data) => {
        toggleLoading(true)
        try {
            const response = await Axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}${path}`,
                data: data,
                headers: getHeaders()
            })
            toggleLoading(false)
            return response.data
        } catch (err) {
            return handleError(err)
        }
    },
    delete: async (path) => {
        toggleLoading(true)
        try {
            const response = await Axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}${path}`,
                headers: getHeaders()
            })
            toggleLoading(false)
            return response.data
        } catch (err) {
            return handleError(err)
        }
    },
}