import React, { useState, useEffect } from "react";
import { HttpService } from "../../../services/httpService";
import Header from "../../shared/header";
import { showMessage } from "../../../redux/common/commonActions";
import { useDispatch } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Grid, TextField } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSelector } from "react-redux";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const PopupChooseProduct = ({ onPressCancel, onPressOk, order, ...props }) => {
	const [products, setProducts] = useState([]);
	// main combo
	let [newProduct, setNewProduct] = useState({
		tastes: [],
	});
	// ăn vặt & thức uống
	let [selectedProducts, setSelectedProducts] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const dispatch = useDispatch();
	const currentStore = useSelector((state) => state.user.currentStore);
	const userInfo = useSelector((state) => state.user.userInfo);

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		caculateHeightForScroll();
	}, [products, selectedTab]);

	const caculateHeightForScroll = () => {
		document.getElementsByClassName("tabpanel")[selectedTab].style.height =
			window.innerHeight -
			(document.getElementById("banner").offsetHeight +
				document.getElementById("tabs-choose-product").offsetHeight +
				document.getElementsByClassName("actions-bottom")[0]
					.offsetHeight) +
			"px";
	};

	const getProducts = async () => {
		if (window.products) return setProducts(window.products);
		let products = await HttpService.get("/products");
		products.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		window.products = products;
		setProducts(products);
	};

	// chọn kem
	const changeProduct = (product) => {
		// nếu thay đổi món thì reset vị
		newProduct.tastes = [];
		setNewProduct({
			...newProduct,
			_id: product._id,
			name: product.name,
			price: product.price,
			quantity: 1,
			type: product.type,
		});
	};

	const Product = ({ product }) => {
		return (
			<div
				className={`product ${
					newProduct._id === product._id && "active"
				}`}
				onClick={(e) => changeProduct(product)}
			>
				{newProduct._id === product._id ? (
					<CheckCircleIcon className="icon-check-selected" />
				) : null}
				<div>{product.name}</div>
				{/* <div>{product.price}</div> */}
			</div>
		);
	};

	// món ăn vặt || thức uống
	const Product1 = ({ product }) => {
		const productSelected = selectedProducts.find(
			(p) => p._id === product._id
		);
		return (
			<div className="">
				<Grid container spacing={0}>
					<Grid item xs={9}>
						<label className="checkbox-container">
							<input
								type="checkbox"
								name={`product${product._id}`}
								value={product._id}
								checked={!!productSelected}
								onChange={(e) =>
									changeSelectedProducts(product)
								}
							/>{" "}
							{product.name}
							<span style={{ fontSize: 14 }}>
								{" "}
								({window.kFormatter(product.price)})
							</span>
							<span className="checkmark"></span>
						</label>
					</Grid>
					{productSelected && (
						<Grid
							item
							xs={3}
							className="actions-change-quantity-container"
						>
							<span
								className="circle-change-quantity decrease-quantity"
								onClick={(e) =>
									updateSelectedProductsQuantity(
										productSelected._id,
										productSelected.quantity - 1
									)
								}
							>
								-
							</span>
							<span className="product-selected-quantity">
								{productSelected.quantity}
							</span>
							<span
								className="circle-change-quantity increase-quantity"
								onClick={(e) =>
									updateSelectedProductsQuantity(
										productSelected._id,
										productSelected.quantity + 1
									)
								}
							>
								+
							</span>
							{/* {productSelected ? <TextField type="number" name={`product-quantity-${product._id}`} inputProps={{min: 1, style: {textAlign: 'center'}}} defaultValue={productSelected? productSelected.quantity: null} onChange={e => updateSelectedProductsQuantity(product._id, e.target.value)}/> : null} */}
							{/* {productSelected ? <input type="text" defaultValue={productSelected? productSelected.quantity: null} onChange={e => updateSelectedProductsQuantity(product._id, e.target.value)}/> : null} */}
						</Grid>
					)}
				</Grid>
			</div>
		);
	};

	// chọn món ăn vặt hoặc thức uống
	const changeSelectedProducts = (product) => {
		let tempProduct = selectedProducts.find((p) => p._id === product._id);
		if (tempProduct) {
			selectedProducts = selectedProducts.filter(
				(p) => p._id != product._id
			);
		} else {
			selectedProducts.push({
				_id: product._id,
				name: product.name,
				price: product.price,
				quantity: 1,
				type: product.type,
			});
		}
		setSelectedProducts(JSON.parse(JSON.stringify(selectedProducts)));
	};

	const updateSelectedProductsQuantity = (productId, quantity) => {
		if (quantity === 0) return;
		let tempProduct = selectedProducts.find((p) => p._id === productId);
		tempProduct.quantity = quantity;
		setSelectedProducts(JSON.parse(JSON.stringify(selectedProducts)));
	};
	// END chọn món ăn vặt hoặc thức uống

	const addProduct = () => {
		if (!newProduct._id && !selectedProducts.length)
			return dispatch(
				showMessage("Vui lòng chọn menu!", "warning", 2000)
			);
		setNewProduct({});
		setSelectedProducts([]);
		onPressOk(selectedProducts.length ? selectedProducts : [newProduct]);
	};

	const handleChangeTab = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<div className="popup-choose-product">
			<Header />
			<div>
				<AppBar position="static" id="tabs-choose-product">
					<Tabs
						value={selectedTab}
						onChange={handleChangeTab}
						aria-label="simple tabs example"
						variant="fullWidth"
					>
						<Tab label="COMBO" {...a11yProps(0)} />
						<Tab label="Món thêm" {...a11yProps(1)} />
						<Tab label="Thức uống" {...a11yProps(2)} />
					</Tabs>
				</AppBar>
				<TabPanel
					value={selectedTab}
					index={0}
					className="tabpanel tabpanel-scroll"
				>
					<div className="menu" style={{ margin: "-24px" }}>
						<div className="list-product">
							{/* <div className="header">Menu CỐC</div> */}
							{/* (p.stores?.length === 0 || p.stores?.includes(currentStore._id)) sản phầm dành cho từng cửa hàng */}
							{products
								.filter(
									(p) =>
										p.type === "MAIN_COMBO" &&
										(p.stores?.length === 0 ||
											p.stores?.includes(
												currentStore?._id ||
													userInfo?.store?._id
											))
								)
								.map((p) => (
									<Product key={p._id} product={p} />
								))}
						</div>
					</div>
				</TabPanel>
				<TabPanel
					className="tabpanel tabpanel-scroll"
					value={selectedTab}
					index={1}
				>
					{products
						.filter(
							(p) =>
								p.type === "EXTRA" &&
								(p.stores?.length === 0 ||
									p.stores?.includes(
										currentStore?._id ||
											userInfo?.store?._id
									))
						)
						.map((p) => (
							<Product1 key={p._id} product={p} />
						))}
				</TabPanel>
				<TabPanel
					className="tabpanel tabpanel-scroll"
					value={selectedTab}
					index={2}
				>
					{products
						.filter(
							(p) =>
								p.type === "DRINKS" &&
								(p.stores?.length === 0 ||
									p.stores?.includes(
										currentStore?._id ||
											userInfo?.store?._id
									))
						)
						.map((p) => (
							<Product1 key={p._id} product={p} />
						))}
				</TabPanel>
			</div>
			<div className="actions-bottom">
				<div
					className={`btn btn-ok btn-add-product ${
						newProduct._id || selectedProducts.length
							? "active"
							: ""
					}`}
					onClick={(e) => addProduct()}
				>
					THÊM MÓN
				</div>
				<div
					className="btn btn-cancel"
					onClick={(e) => onPressCancel()}
				>
					ĐÃ CHỌN ({order.products.length})
				</div>
			</div>
		</div>
	);
};

export default PopupChooseProduct;
