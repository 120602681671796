import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { HttpService } from '../../../services/httpService';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';

const PopupConfirmCreateOrder = ({ order, open, onClose, ...props }) => {
    const [extraInfo, setExtraInfo] = useState({ tableName: '' })
    // const [selectedStore, setSelectedStore] = useState(null)
    // const [stores, setStores] = useState([])

    const userInfo = useSelector(state => state.user.userInfo)
    const roles = userInfo && (userInfo.roles || []) || []

      // for ADMIN
    const currentStore = useSelector((state) => state.user.currentStore);

    // useEffect(() => {
    //     getStores()
    // }, [])

    const changeTableName = e => {
        setExtraInfo({
            ...extraInfo,
            tableName: e.target.value
        })
    }

    // const getStores = async () => {
    //     if (window.stores && window.stores.length > 0) {
    //         setStores(window.stores)
    //         setSelectedStore(window.stores[0]._id)
    //         return
    //     }
    //     const stores = await HttpService.get('/stores')
    //     if (stores && stores.length > 0) {
    //         setStores(stores)
    //         setSelectedStore(currentStore?._id || stores[0]._id)
    //     }
    //     window.stores = stores
    // }

    // const changeStore = e => {
    //     setSelectedStore(e.target.value)
    // }

    const createOrder = async () => {
        if (!extraInfo.tableName) return;

        order.extraInfo = extraInfo
        if (currentStore?._id) {
            order.store = +currentStore?._id;
        }
        const data = await HttpService.post('/orders', order)
        props.history.push('/orders')
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            fullWidth
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Xác nhận đơn hàng</DialogTitle>
            <DialogContent dividers>
                {/* {!roles.includes('END_USER') && <FormControl className='form-control'>
                    <InputLabel id="select-store">Chi nhánh</InputLabel><Select
                        labelId="select-store-label"
                        value={selectedStore}
                        onChange={e => changeStore(e)}
                    >
                        {stores.map(store => <MenuItem key={store._id} value={store._id}>{store.name}</MenuItem>)}
                    </Select></FormControl>} */}
                <TextField className='form-control' defaultValue={extraInfo.tableName} label='Nhập tên bàn' onChange={e => changeTableName(e)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={e => createOrder()} color="primary">
                    Ok
            </Button>
                <Button onClick={e => onClose()} color="primary">
                    Đóng
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupConfirmCreateOrder;
