import React, { useState } from 'react';
import { useAuth } from '../../context/auth';
import { HttpService } from './../../services/httpService';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../redux';
import { useForm } from 'react-hook-form';

const Login = () => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { setAuthTokens } = useAuth();

    // REDUX: GET_USER_INFO
    const userInfo = useSelector(state => state.user.userInfo)

    // REDUX: SET_USER_INFO
    const dispatch = useDispatch()

    const { register, handleSubmit, errors } = useForm();

    const login = async user => {
        const response = await HttpService.post('/auth/login', user)
        if (response) {
            if (response.user) {
                setAuthTokens(response.token);
                dispatch(setUserInfo(response.user))
                setLoggedIn(true);
                delete window.products;
            } else {
                setErrorMessage(response.message);
            }
        }
    }

    if (isLoggedIn) {
        if (userInfo.roles.some(r => r === 'END_USER')) {
            return <Redirect to='/orders' />;
        } else {
            return <Redirect to='/' />;
        }
    }

    return (
        <div>
            <div className="page-name">Đăng nhập vào LU</div>
            <form onSubmit={handleSubmit(login)} className="login-form">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="username"
                            label="Tên đăng nhập"
                            type="text"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            label="Mật khẩu"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            fullWidth
                            inputRef={register}
                            defaultValue=""
                        />
                    </Grid>
                    {errorMessage && 
                    <Grid item xs={12}>
                        <div className='error-message'>{errorMessage}</div>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" fullWidth size="medium">
                            Đăng nhập
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default Login;