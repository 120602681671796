import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { HttpService } from '../../../services/httpService';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';

const PopupChangeOrderTableName = ({ order, open, onClose, ...props }) => {
    const [extraInfo, setExtraInfo] = useState(order?.extraInfo)

    useEffect(() => {
    }, [])

    const changeTableName = e => {
        setExtraInfo({
            ...extraInfo,
            tableName: e.target.value
        })
    }

    const updateOrder = async () => {
        if (!extraInfo.tableName) return;
        const data = await HttpService.put(`/orders/${order._id}`, { extraInfo })
        onClose(true);
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            fullWidth
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Chuyển bàn</DialogTitle>
            <DialogContent dividers>
                <TextField className='form-control' defaultValue={extraInfo?.tableName} label='Nhập tên bàn mới' onChange={e => changeTableName(e)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={e => updateOrder()} color="primary">
                    Ok
            </Button>
                <Button onClick={e => onClose()} color="primary">
                    Đóng
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupChangeOrderTableName;
