import React, { useEffect, useState } from 'react';
import { HttpService } from '../../services/httpService';
import { withRouter } from 'react-router-dom';

const Report = (props) => {
    const [data, setData] = useState({})
    useEffect(() => {
        getFullReport()
    }, [])

    const getFullReport = async () => {
        const data = await HttpService.get('/reports/getFullReport')
        setData(data)
    }

    const navigate = path => {
        props.history.push(path)
    }

    return <>
        {data && data.order &&
        <div className="reports">
            <div className="report-item" onClick={e => navigate('/reports/revenue')}>
                <div className="report-item-title">Doanh thu</div>
            </div>
            <div className="report-item" onClick={e => navigate('/reports/order')}>
                <div className="report-item-title">Đơn hàng ({data.order.totalCount})</div>
                {data.order.products.map((p, idx) => <div key={idx} className="sum-of-order-type">{p.name} ({p.count})</div>)}
            </div>
        </div>}
    </>
};

export default withRouter(Report);