import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/common/commonActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { HttpService } from '../../services/httpService';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import md5 from 'md5'

const UserDetail = ({onClose, ...props}) => {
    const { register, handleSubmit, errors, control } = useForm();
    const [stores, setStores] = useState([])
    const [defaultStore, setDefaultStore] = useState(null)
    const dispatch = useDispatch()
    const [user, setUser] = useState({})
    
    useEffect(() => {
        getStores()
    }, [])

    const save = async (data) => {
        if (!data.store || !data.username || !data.password || !data.nickName) return dispatch(showMessage('Vui lòng nhập đầy đủ thông tin!', 'warning'))
        if (data.password && data.password.length < 6) return dispatch(showMessage('Mật khẩu phải ít nhất 6 ký tự!', 'warning'))
        const response = await HttpService.post('/users', {...data, password: md5(data.password)})
        if (response) {
            dispatch(showMessage('Thêm người dùng thành công'))
            onClose(true)
        }
    }

    const getStores = async () => {
        const stores = await HttpService.get('/stores')
        if (stores) {
            setStores(stores)
            setDefaultStore(stores[0]._id)
        }
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Tạo mới người dùng</DialogTitle>
            <DialogContent dividers>
            <form onSubmit={handleSubmit(save)}>
                <div>
                    {defaultStore ? <FormControl className='form-control'>
                        <InputLabel id="select-store">Chi nhánh</InputLabel>
                        <Controller as={
                            <Select>
                                {stores.map(store => <MenuItem key={store._id} value={store._id}>{store.name}</MenuItem>)}
                            </Select>
                        }
                            defaultValue={defaultStore}
                            name='store'
                            control={control}
                        />
                    </FormControl> : null}
                    <TextField name='username' label="Tên đăng nhập" className='form-control' inputRef={register} />
                    <TextField name='nickName' label='Tên' placeholder="Ví dụ: Đào, chỉ 1 chữ thôi nhé" className='form-control' inputRef={register} />
                    <TextField name='password' label='Mật khẩu' placeholder="6 kí tự" className='form-control' inputRef={register} type="password"/>
                </div>
            </form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSubmit(save)}>
                        Tạo
                </Button>
                <Button onClick={e => onClose()} color="primary">
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserDetail;