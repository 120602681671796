import React, { useEffect, useState } from 'react';
import { HttpService } from '../../services/httpService';
import { withRouter } from 'react-router-dom';
import {Grid} from "@material-ui/core";

const UserSchedule = (props) => {
    const [workSchedules, setWorkSchedules] = useState([])
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [numDaysOfMonth, setNumDaysOfMonth] = useState(0)
    useEffect(() => {
        changeMonth(selectedMonth)
        getWorkSchedules()
    }, [])

    const getWorkSchedules = async () => {
        // const data = await HttpService.get('/workSchedules')
        const data = []
        setWorkSchedules(data)
    }

    const navigate = path => {
        props.history.push(path)
    }

    const changeMonth = month => {
        setSelectedMonth(month)
        getListDayOfMonth(selectedYear, month)
    }

    const getListDayOfMonth = (year, month) => {
        const numDaysOfMonth = new Date(year, month, 0).getDate();
        setNumDaysOfMonth(numDaysOfMonth)
    }

    const renderMonth = () => {
        return <Grid container className="mt10">
            {
                [1,2,3,4,5,6,7,8,9,10,11,12].map(month => {
                    return <Grid item xs={1} className={`month text-center ${selectedMonth === month ? 'selected' : ''}`} onClick={e => changeMonth(month)}>{month}</Grid>
                })
            }
        </Grid>
    }

    const renderDaysOfMonth = () => {
        let days = [];
        for (let i = 1; i <= numDaysOfMonth; i++) {
            days.push(<div>{i}</div>)
        }
        return days;
    }

    const showErrorGeo = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
        }
    }

    const checkIn = async () => {
        if (navigator.geolocation) {
            try {
                navigator.geolocation.getCurrentPosition(async position => {
                    console.log(position)
                    const reqData = {
                        coordinateCheckIn: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                    }
                    const data = await HttpService.post(`/checkins/checkin`, reqData)
                }, showErrorGeo);
            } catch (err) {
                alert('dfd');
            }

        } else {
            alert('Bạn chưa cấp phép truy cập vị trí cho ứng dụng!')
        }
    }

    const checkOut = async () => {
        if (navigator.geolocation) {
            try {
                navigator.geolocation.getCurrentPosition(async position => {
                    console.log(position)
                    const reqData = {
                        coordinateCheckOut: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                    }
                    const data = await HttpService.post(`/checkins/checkout`, reqData)
                }, showErrorGeo);
            } catch (err) {
                alert('dfd');
            }

        } else {
            alert('Bạn chưa cấp phép truy cập vị trí cho ứng dụng!')
        }
    }

    const requestCheckInOut = async () => {
        const reqData = {}
        const data = await HttpService.post(`/checkins`, reqData)
    }

    const showPopupRequestCheckInOut = () => {

    }

    return <>
        <div className='page-name'>Lịch làm việc</div>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div style={{marginTop: 10}}>
                <div className="btn mr10" onClick={e => checkIn()}>Check In</div>
                <div className="btn mr10" onClick={e => checkOut()}>Check Out</div>
                <div className="btn mr10" onClick={e => showPopupRequestCheckInOut()}>Tạo yêu cầu CheckIn/Out</div>
            </div>
        </div>
    </>
};

export default withRouter(UserSchedule);
