import React, { useState } from 'react';
import { Link, Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import About from '../about/about';
import UserList from '../user/userList';
import Home from '../home/home'
import UserDetail from '../user/userDetail';
import OrderList from '../order/orderList';
import OrderDetail from '../order/orderDetail';
import Header from './header';
import MainNav from './mainNav';
import Report from '../report/report';
import { AuthContext, useAuth } from "../../context/auth";
import Login from '../login/login';
import ReportOrder from '../report/reportOrder';
import Expense from './../expense/expense';
import { CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ReportRevenue from './../report/reportRevenue';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { showMessage } from '../../redux/common/commonActions';
import UserSchedule from '../work-schedule/userSchedule';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Layout = (props) => {
    const existingTokens = JSON.parse(localStorage.getItem("tokens"));
    const [authTokens, setAuthTokens] = useState(existingTokens);
    const loading = useSelector(state => state.common.loading)
    const message = useSelector(state => state.common.message)
    const dispatch = useDispatch()

    const setTokens = (data) => {
        localStorage.setItem("tokens", JSON.stringify(data));
        setAuthTokens(data);
    }

    const hideMessage = () => {
        dispatch(showMessage(false))
    }

    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
            <Router>
                <Header />
                <section className="main-content">
                    <Switch>
                        <Route path="/login" component={Login} />
                        {!authTokens ?
                            <Route>
                                <Redirect to="/login" />
                            </Route>
                            : <>
                                <Route path="/" exact>
                                    <Home />
                                </Route>
                                <Route path="/orders" exact>
                                    <OrderList />
                                </Route>
                                <Route path="/orders/:orderId">
                                    <OrderDetail />
                                </Route>
                                <Route path="/reports" exact>
                                    <Report />
                                </Route>
                                <Route path="/reports/order">
                                    <ReportOrder />
                                </Route>
                                <Route path="/reports/revenue">
                                    <ReportRevenue />
                                </Route>

                                <Route path="/expenses" exact>
                                    <Expense />
                                </Route>

                                <Route path="/users" exact>
                                    <UserList />
                                </Route>
                                <Route path="/users/:id">
                                    <UserDetail />
                                </Route>
                                {/* <Route path="/work-schedule">
                                    <UserSchedule />
                                </Route> */}
                            </>}
                    </Switch>
                </section>
                {/* <footer>Copyright &copy; Hotido</footer> */}
                {loading && <div className="loading"><CircularProgress /></div>}

                <Snackbar open={!!message.text} autoHideDuration={message.hideDuration} onClose={e => hideMessage()}>
                    <Alert onClose={e => hideMessage()} severity={message.type}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Router>
        </AuthContext.Provider>
    );
};

export default Layout;