export const StatusMap = {
    INIT: 'MỚI',
    PROCESSING: 'CHỜ THANH TOÁN',
    FINISHED: 'ĐÃ THANH TOÁN',
}

export const ExpenseTypeMap = {
    INVEST: 'Đầu tư',
    DAILY: 'Nguyên vật liệu hàng ngày',
    SPACE: 'Mặt bằng',
    OPERATING: 'Hoạt động (điện, nước, ...)',
    MANAGEMENT: 'Quản lý (Mail, kế toán, ...)',
    SALARY: 'Lương nhân viên',
    OTHER: 'Khác (ăn uống ngoài, đi ngoài kh, ...)',
}