import React, { useState, useEffect } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { HttpService } from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ExpenseByDay from './expenseByDay';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ExpenseTypeMap } from '../../constants/common';
import IconExcel from '../../public/icons/excel-icon.svg'

const Expense = (props) => {
    const [reportExpenses, setReportExpenses] = useState([])
    const [filter, setFilter] = useState({
        fromDate: new Date(`${moment(new Date().setDate(1)).format('YYYY-MM-DD')}`),
        toDate: new Date(),
        expenseType: 0,
        createdBy: 0
    })

    const [totalExpenses, setTotalExpenses] = useState(0)
    const [totalExpensesAmount, setTotalExpensesAmount] = useState(0)

    const [expenseByDay, setExpenseByDay] = useState('') // 15/04/2020

    const handleDateChange = (date, field) => {
        if (field === 'fromDate') {
            setFilter({
                ...filter,
                fromDate: date,
            });
        } else if (field == 'toDate') {
            setFilter({
                ...filter,
                toDate: date,
            });
        }
    };

    useEffect(() => {
        getReportExpenseGroupByDate()
    }, [filter])

    const getReportExpenseGroupByDate = async () => {
        const queryParams = {
            fromDate: filter.fromDate.setHours(0, 0, 0),
            toDate: filter.toDate.setHours(23, 59, 59),
            createdBy: filter.createdBy
        }
        if (filter.expenseType) {
            queryParams.type = filter.expenseType
        }
        const data = await HttpService.get('/reports/getReportExpenseGroupByDate', queryParams)
        if (data) {
            setReportExpenses(data)

            let totalExpenses = 0
            let totalExpensesAmount = 0

            data.forEach(it => {
                totalExpenses += it.totalExpenses
                totalExpensesAmount += it.totalAmount
            })
            setTotalExpenses(totalExpenses)
            setTotalExpensesAmount(totalExpensesAmount)
        }
    }

    const showExpenseByDay = stringDate => {
        setExpenseByDay(stringDate)
    }

    const hideExpenseByDay = () => {
        setExpenseByDay(null)
        getReportExpenseGroupByDate()
    }

    const changeExpenseType = e => {
        setFilter({
            ...filter,
            expenseType: e.target.value,
        });
    }

    const changeCreatedBy = e => {
        setFilter({
            ...filter,
            createdBy: e.target.value,
        });
    }

    const exportExcel = async () => {
        const queryParams = {
            fromDate: filter.fromDate.setHours(0, 0, 0),
            toDate: filter.toDate.setHours(23, 59, 59),
            createdBy: filter.createdBy,
            export: true,
        }
        if (filter.expenseType) queryParams.type = filter.expenseType
        HttpService.get('/expenses', queryParams)
    }

    const ReportByDate = ({ data }) => {
        return <TableRow onClick={e => showExpenseByDay(data.date)}>
            <TableCell component="th" scope="row">
                {data.date}
            </TableCell>
            <TableCell align="right">{data.totalExpenses}</TableCell>
            <TableCell align="right">{window.formatMoney(data.totalAmount, '')}</TableCell>
        </TableRow>
    }

    return (
        <div>
            {expenseByDay ? <ExpenseByDay stringDate={expenseByDay} createdBy={filter.createdBy} type={filter.expenseType} onClose={e => hideExpenseByDay()} /> :
                <>
                    <div className='page-name'>Quản lý chi phí <img src={IconExcel} className='page-name-icon excel-icon' onClick={e => exportExcel()} /></div>
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <FormControl className='form-control'>
                            <InputLabel id="select-type-label">Loại chi phí</InputLabel>
                            <Select
                                labelId="select-type-label"
                                value={filter.expenseType}
                                onChange={e => changeExpenseType(e)}
                            >
                                <MenuItem value={0}>Tất cả</MenuItem>
                                {Object.keys(ExpenseTypeMap).map(key => <MenuItem key={key} value={key}>{ExpenseTypeMap[key]}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl className='form-control'>
                            <InputLabel id="select-user-label">Người tạo</InputLabel>
                            <Select
                                labelId="select-type-label"
                                value={filter.createdBy}
                                onChange={e => changeCreatedBy(e)}
                            >
                                <MenuItem value={0}>Tất cả</MenuItem>
                                {[{ _id: 2, nickName: 'Đào' }, { _id: 3, nickName: 'Sơn' }].map(u => <MenuItem key={u._id} value={u._id}>{u.nickName}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        id="123"
                                        margin="normal"
                                        label="Từ ngày"
                                        format="dd/MM/yyyy"
                                        value={filter.fromDate}
                                        onChange={date => handleDateChange(date, 'fromDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Từ ngày',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        id="456"
                                        margin="normal"
                                        label="Đến ngày"
                                        format="dd/MM/yyyy"
                                        value={filter.toDate}
                                        onChange={date => handleDateChange(date, 'toDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Đến ngày',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider >
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ngày</TableCell>
                                        <TableCell align="right">Số lần chi tiêu</TableCell>
                                        <TableCell align="right">Tổng tiền (đ)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">{totalExpenses}</TableCell>
                                        <TableCell align="right">{window.formatMoney(totalExpensesAmount, '')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportExpenses.map(r => <ReportByDate key={r.date} data={r} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="create-expense" onClick={e => showExpenseByDay(moment().format('DD/MM/YYYY'))}>
                        +
            </div>
                </>}
        </div>
    );
};

export default withRouter(Expense);