import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { HttpService } from '../../services/httpService';
import { ExpenseTypeMap } from '../../constants/common';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/common/commonActions';

const AddExpense = ({ stringDate, onClose }) => {
    const { register, handleSubmit, errors, control } = useForm();
    const [stores, setStores] = useState([])
    const [defaultStore, setDefaultStore] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        getStores()
    }, [])

    const save = async data => {
        if (!data.store || !data.type || !data.description || !data.totalAmount) return dispatch(showMessage('Vui lòng nhập đầy đủ thông tin!', 'warning'))
        const response = await HttpService.post('/expenses', data)
        if (response) {
            onClose(true)
        }
    }

    const getStores = async () => {
        const stores = await HttpService.get('/stores')
        if (stores) {
            setStores(stores)
            setDefaultStore(stores[0]._id)
        }
    }

    return (
        <div>
            <div className='page-name'>Thêm chi phí ngày {stringDate}</div>
            <form onSubmit={handleSubmit(save)}>
                <div style={{ padding: 10 }}>
                    {defaultStore ? <FormControl className='form-control'>
                        <InputLabel id="select-store">Chi nhánh</InputLabel>
                        <Controller as={
                            <Select>
                                {stores.map(store => <MenuItem key={store._id} value={store._id}>{store.name}</MenuItem>)}
                            </Select>
                        }
                            defaultValue={defaultStore}
                            name='store'
                            control={control}
                        />
                    </FormControl> : null}
                    <FormControl className='form-control'>
                        <InputLabel id="select-expense-type">Loại chi phí</InputLabel>
                        <Controller as={
                            <Select>
                                {Object.keys(ExpenseTypeMap).map(key => <MenuItem key={key} value={key}>{ExpenseTypeMap[key]}</MenuItem>)}
                            </Select>
                        }
                            name='type'
                            control={control}
                        />
                    </FormControl>
                    <TextField name='description' label="Mô tả" className='form-control' inputRef={register} />
                    <TextField name='totalAmount' type='number' label='Tổng tiền' className='form-control' inputRef={register} />
                </div>
                <div className="text-center" style={{ paddingTop: 20 }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit(save)}>
                        Lưu
                </Button>
                    <Button variant="contained" onClick={e => onClose()} style={{ marginLeft: 10 }}>
                        Quay lại
                </Button>
                </div>
            </form>
        </div>
    );
};

export default AddExpense;