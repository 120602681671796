import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../public/logo.png'
import { useSelector } from 'react-redux';

const Home = (props) => {
    const navigate = path => {
        props.history.push(path)
    }

    const userInfo = useSelector(state => state.user.userInfo)
    const roles = userInfo && (userInfo.roles || []) || []
    return (
        <div className="modules">
            {roles.includes('ADMIN') &&
                <div className="module" onClick={e => navigate('reports')}>
                    <img src={logo} className="module-icon animated infinite swing delay-2s" />
                Thống kê
            </div>
            }
            <div className="module" onClick={e => navigate('orders')}>
                <img src={logo} className="module-icon animated infinite bounce delay-2s" />
                Đơn hàng
            </div>
            {/* <div className="module" onClick={e => navigate('work-schedule')}>
                <img src={logo} className="module-icon animated infinite bounce delay-2s" />
                Lịch làm việc
            </div> */}
            {roles.includes('ADMIN') &&
                <div className="module" onClick={e => navigate('expenses')}>
                    <img src={logo} className="module-icon animated infinite swing delay-2s" />
                QL chi phí
            </div>
            }
            {roles.includes('SUPER_ADMIN') &&
                <div className="module" onClick={e => navigate('users')}>
                    <img src={logo} className="module-icon animated infinite swing delay-2s" />
                QL người dùng
            </div>
            }
        </div>
    );
};

export default withRouter(Home);
