import React, { useState, useEffect } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { HttpService } from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import IconExcel from '../../public/icons/excel-icon.svg'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const ReportOrder = () => {
    const [reportOrders, setReportOrders] = useState([])
    const [filter, setFilter] = useState({
        fromDate: new Date(`${moment(new Date().setDate(1)).format('YYYY-MM-DD')}`),
        toDate: new Date(),
        storeId: null
    })

    const [totalOrders, setTotalOrders] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [totalOrdersAmount, setTotalOrdersAmount] = useState(0)

    const [selectedStore, setSelectedStore] = useState(0)
    const [stores, setStores] = useState([])

    const handleDateChange = (date, field) => {
        if (field === 'fromDate') {
            setFilter({
                ...filter,
                fromDate: date,
                export: false
            });
        } else if (field == 'toDate') {
            setFilter({
                ...filter,
                toDate: date,
                export: false
            });
        }
    };

    useEffect(() => {
        getReportOrderGroupByDate()
        getStores()
    }, [filter])

    const getStores = async () => {
        if (window.stores && window.stores.length > 0) {
            setStores(window.stores)
            // setSelectedStore(window.stores[0]._id)
            return
        }
        const stores = await HttpService.get('/stores')
        if (stores && stores.length > 0) {
            setStores(stores)
            // setSelectedStore(stores[0]._id)
        }
        window.stores = stores
    }

    const changeStore = e => {
        setSelectedStore(e.target.value)
        setFilter({
            ...filter,
            store: e.target.value,
            export: false
        });
    }

    const getReportOrderGroupByDate = async () => {
        const queryParams = {
            fromDate: filter.fromDate.setHours(0, 0, 0),
            toDate: filter.toDate.setHours(23, 59, 59),
        }
        if (filter.store) {
            queryParams.store = filter.store;
        }
        const data = await HttpService.get('/reports/getReportOrderGroupByDate', queryParams)
        if (data) {
            setReportOrders(data)
        
            let totalOrders = 0
            let totalOrdersAmount = 0
            let totalProducts = 0
    
            data.forEach(it => {
                totalOrders += it.totalOrders
                totalOrdersAmount += it.totalAmount
                totalProducts += it.totalProducts
            })
            setTotalOrders(totalOrders)
            setTotalOrdersAmount(totalOrdersAmount)
            setTotalProducts(totalProducts)
        }
    }

    const ReportByDate = ({ data }) => {
        return <TableRow>
            <TableCell component="th" scope="row">
                {data.date}
            </TableCell>
            <TableCell align="right">{data.totalOrders}</TableCell>
            <TableCell align="right">{window.formatMoney(data.totalAmount, '')}</TableCell>
        </TableRow>
    }

    const exportExcel = async () => {
        const queryParams = {
            fromDate: filter.fromDate.setHours(0, 0, 0),
            toDate: filter.toDate.setHours(23, 59, 59),
            export: true
        }
        HttpService.get('/orders', queryParams)
    }

    return (
        <div>
            <div className='page-name'>Thống kê đơn hàng <img src={IconExcel} className='page-name-icon excel-icon' onClick={e => exportExcel()}/></div>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                id="123"
                                margin="normal"
                                label="Từ ngày"
                                format="dd/MM/yyyy"
                                value={filter.fromDate}
                                onChange={date => handleDateChange(date, 'fromDate')}
                                KeyboardButtonProps={{
                                    'aria-label': 'Từ ngày',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                id="456"
                                margin="normal"
                                label="Đến ngày"
                                format="dd/MM/yyyy"
                                value={filter.toDate}
                                onChange={date => handleDateChange(date, 'toDate')}
                                KeyboardButtonProps={{
                                    'aria-label': 'Đến ngày',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl className='form-control'>
                                <InputLabel id="select-store">Chi nhánh</InputLabel><Select
                                labelId="select-store-label"
                                value={selectedStore}
                                onChange={e => changeStore(e)}
                            >
                                <MenuItem value={0}>Tất cả</MenuItem>
                                {stores.map(store => <MenuItem key={store._id} value={store._id}>{store.name}</MenuItem>)}
                            </Select></FormControl>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider >
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Ngày</TableCell>
                                <TableCell align="right">Đơn hàng</TableCell>
                                <TableCell align="right">Tổng tiền (đ)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">{totalOrders}</TableCell>
                                <TableCell align="right">{window.formatMoney(totalOrdersAmount, '')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportOrders.map(r => <ReportByDate key={r.date} data={r} />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default ReportOrder;