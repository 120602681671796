import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Confirm = ({open, onClose, title, acceptText, cancelText, ...props}) => {
    const handleClose = aggree => {
        onClose(aggree)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={e => handleClose(true)} color="primary" autoFocus>
                    {acceptText}
          </Button>
                <Button onClick={e => handleClose(false)} color="primary">
                    {cancelText}
          </Button>
            </DialogActions>
        </Dialog>
    );
};

Confirm.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    acceptText: PropTypes.string,
    cancelText: PropTypes.string
};

Confirm.defaultProps = {
    open: false,
    title: 'Xác nhận',
    acceptText: 'OK',
    cancelText: 'Đóng'
}

export default Confirm;