import React, { useEffect, useState } from 'react';
import { HttpService } from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import { ExpenseTypeMap } from '../../constants/common';
import AddExpense from './addExpense';
import moment from 'moment'

const ExpenseByDay = ({ stringDate = '', createdBy, type, onClose }) => {
    const [expenses, setExpenses] = useState([])
    const [totalExpensesAmount, setTotalExpensesAmount] = useState(0)
    const [addExpense, setAddExpense] = useState(false)
    const isToday = stringDate === moment().format('DD/MM/YYYY')

    useEffect(() => {
        getExpenses()
    }, [])

    const getExpenses = async () => {
        const [date, month, year] = stringDate.split('/')
        const fromDate = new Date(year, month - 1, date, 0, 0, 0).getTime()
        const toDate = new Date(year, month - 1, date, 23, 59, 59).getTime()

        const filter = { fromDate, toDate, createdBy }
        if (type) filter.type = type
        const data = await HttpService.get('/expenses', filter)
        setExpenses(data.docs)
        const totalExpensesAmount = data.docs.reduce((a, b) => {
            a += b.totalAmount
            return a
        }, 0)
        setTotalExpensesAmount(totalExpensesAmount)
    }

    const removeExpense = async id => {
        const response = await HttpService.delete(`/expenses/${id}`)
        if (response) {
            getExpenses()
        }
    }

    const Expense = ({ expense }) => {
        return <TableRow>
            <TableCell component="th" scope="row">
                {ExpenseTypeMap[expense.type]}
            </TableCell>
            <TableCell component="th" scope="row">
                {expense.description}
            </TableCell>
            <TableCell align="right">{window.formatMoney(expense.totalAmount, '')}</TableCell>

            {isToday && <TableCell component="th" scope="row">
                <span className="btn-remove" onClick={e => removeExpense(expense._id)}>&#10005;</span>
            </TableCell>}
        </TableRow>
    }

    const showAddExpense = () => {
        setAddExpense(true)
    }

    const hideAddExpense = (refreshData) => {
        setAddExpense(false)
        if (refreshData) {
            getExpenses()
        }
    }

    return (<>
        {
            addExpense ? <AddExpense stringDate={stringDate} onClose={e => hideAddExpense(e)} />
                :
                <div>
                    <div className='page-name'>Chi phí ngày {stringDate}</div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Loại chi phí</TableCell>
                                    <TableCell>Mô tả</TableCell>
                                    <TableCell align="right">Tổng tiền</TableCell>
                                    {isToday && <TableCell>#</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right">{window.formatMoney(totalExpensesAmount, '')}</TableCell>
                                    {isToday && <TableCell></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expenses.map(e => <Expense key={e._id} expense={e} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="text-center" style={{ paddingTop: 20 }}>
                        {isToday ? <Button variant="contained" color="primary" onClick={e => showAddExpense()}>
                            Thêm mới
                </Button> : null }
                        <Button variant="contained" onClick={e => onClose()} style={{ marginLeft: 10 }}>
                            Quay lại
                </Button>
                    </div>
                </div>
        }
    </>
    );
};

export default ExpenseByDay;