import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../services/httpService";
import { StatusMap } from "../../constants/common";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useSelector } from "react-redux";
import PopupDiscount from "./popup/popupDiscount";
import PopupRevenueToDay from "./popup/popupRevenueToDay";
import SocketContext from "../../context/socket";
import Confirm from "./../common/confirm";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PopupChangeOrderTableName from "./popup/popupChangeOrderTableName";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const OrderList = ({ socket, ...props }) => {
	const [orders, setOrders] = useState([]);
	const [stores, setStores] = useState([]);
	const [processingOrderId, setProcessingOrderId] = useState(null);
	const [showPopupDiscount, setShowPopupDiscount] = useState(false);

	const [showPopupRevenueToday, setShowPopupRevenueToday] = useState(false);
	const [showConfirmRemoveOrder, setShowConfirmRemoveOrder] = useState(false);

	const [changeOrderTableName, setChangeOrderTableName] = useState(null);

	const userInfo = useSelector((state) => state.user.userInfo);
	const roles = (userInfo && (userInfo.roles || [])) || [];

	// for ADMIN
	const currentStore = useSelector((state) => state.user.currentStore);

	const [selectedTab, setSelectedTab] = React.useState(0);
	const theme = useTheme();

	const productTypes = [
		{
			type: "MAIN_COMBO",
			name: "COMBO",
		},
		{
			type: "EXTRA",
			name: "MÓN THÊM",
		},
		{
			type: "DRINKS",
			name: "THỨC UỐNG",
		},
	];

	useEffect(() => {
		getOrders();
		getStores();
	}, []);

	useEffect(() => {
		socket.on("message", (message) => {
			console.log(socket);
			console.log("message from server: ", message);
			if (message.type === "refresh" && message.source === "orders") {
				getOrders();
			}
		});
	}, []);

	const getOrders = async () => {
		const data = await HttpService.get("/orders", {
			fromDate: new Date().setHours(0, 0, 0),
			toDate: new Date().setHours(23, 59, 59),
		});
		if (data) {
			setOrders(data.docs);
		}
	};

	const getStores = async () => {
		if (window.stores && window.stores.length > 0) {
			setStores(window.stores);
			// setSelectedStore(window.stores[0]._id)
			return;
		}
		const stores = await HttpService.get("/stores");
		if (stores && stores.length > 0) {
			setStores(stores);
			// setSelectedStore(stores[0]._id)
		}
		window.stores = stores;
	};

	const getStoreById = (storeId) => {
		return (stores || []).find((s) => s._id === storeId) || {};
	};

	const changeOrderStatus = async (orderId, status) => {
		if (status === "FINISHED") {
			setProcessingOrderId(orderId);
			setShowPopupDiscount(true);
		} else {
			const order = await HttpService.put(`/orders/${orderId}`, {
				status,
			});
			getOrders();
		}
	};

	const Order = ({ order }) => {
		return (
			<div key={order._id} className="order">
				<div className="table-name-header">
					{roles.includes("ADMIN")
						? getStoreById(order.store).name + " - "
						: ""}
					Bàn: {order.extraInfo.tableName}
					<span className="order-time">
						{["INIT", "PROCESSING"].includes(order.status) && (
							<span
								className="material-icons change-table-name"
								onClick={(e) => setChangeOrderTableName(order)}
							>
								published_with_changes
							</span>
						)}
						{moment(order.createdAt).format("HH:mm")}
					</span>
				</div>
				<div className="product-list">
					{order.tempProducts?.length > 0 && (
						<div
							style={{
								marginBottom: 10,
								fontWeight: "bold",
								color: "red",
								fontSize: 15,
							}}
						>
							*** GỌI THÊM ***
						</div>
					)}
					{productTypes.map((pt) => {
						const productsOfType = (
							order.tempProducts?.length > 0
								? order.tempProducts
								: order.products
						).filter((p) => p.type === pt.type);
						return productsOfType.length ? (
							<div>
								<div>
									<strong style={{ color: "#f285b1" }}>
										{pt.name}
									</strong>
								</div>
								{productsOfType.map((p, idx) => (
									<Product key={idx} product={p} />
								))}
							</div>
						) : null;
					})}
					<div className="order-total-amount">
						Tổng tiền:{" "}
						{order.discountAmount ? (
							<>
								<span className="total-amount-before-discount">
									{window.formatMoney(
										order.totalAmount + order.discountAmount
									)}
								</span>
								<span className="total-amount-after-discount">
									{" "}
									{window.formatMoney(order.totalAmount)}
								</span>
							</>
						) : (
							<span>{window.formatMoney(order.totalAmount)}</span>
						)}
					</div>
					{order.notes && order.notes.length ? (
						<div>
							Ghi chú:{" "}
							<span className="red-text">
								{order.notes[order.notes.length - 1].note}
							</span>
						</div>
					) : null}
					<div>
						{roles.includes("SUPER_ADMIN") && (
							<div
								onClick={(e) => removeOrder(order._id)}
								className="btn btn-remove-dish mt10 mr5"
							>
								Hủy
							</div>
						)}
						{(order.status != "FINISHED" ||
							roles.includes("SUPER_ADMIN")) && (
							<div
								onClick={(e) =>
									props.history.push(`/orders/${order._id}`)
								}
								className="btn btn-step-processing mr5 mt10"
							>
								Chỉnh sửa
							</div>
						)}
						{(order.status === "INIT" ||
							(roles.includes("SUPER_ADMIN") &&
								order.status != "PROCESSING")) && (
							<div
								onClick={(e) =>
									changeOrderStatus(order._id, "PROCESSING")
								}
								className="btn btn-step-processing mr5 mt10"
							>
								Đã lên món
							</div>
						)}
						{order.status === "PROCESSING" && (
							<div
								onClick={(e) =>
									changeOrderStatus(order._id, "FINISHED")
								}
								className="btn btn-step-payment mt10"
							>
								Thanh toán
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const Product = ({ product, ...props }) => {
		return (
			<div key={product._id} className="dish-name">
				<strong> {product.name}</strong> (
				{window.formatMoney(product.price)} x {product.quantity})
			</div>
		);
	};

	const removeOrder = async (id) => {
		setProcessingOrderId(id);
		setShowConfirmRemoveOrder(true);
	};

	const confirmRemoveOrder = async (aggree) => {
		if (aggree) {
			const response = await HttpService.delete(
				`/orders/${processingOrderId}`
			);
			getOrders();
		}
		setShowConfirmRemoveOrder(false);
	};

	const hidePopupDiscount = (refreshOrders) => {
		setShowPopupDiscount(false);
		if (refreshOrders) {
			getOrders();
		}
	};

	const handleChangeTab = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const handleChangeTabIndex = (index) => {
		setSelectedTab(index);
	};

	const onClosePopupChangeOrderTableName = (e) => {
		if (e) {
			getOrders();
		}
		setChangeOrderTableName(null);
	};

	return (
		<div className="orders">
			<div className="page-name">
				Đơn hàng{" "}
				<MonetizationOnIcon
					className="page-name-icon"
					onClick={(e) => setShowPopupRevenueToday(true)}
				/>
			</div>

			<AppBar position="static" color="default">
				<Tabs
					value={selectedTab}
					onChange={handleChangeTab}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					<Tab
						label={`Mới (${
							orders.filter(
								(o) =>
									o.status === "INIT" &&
									(!currentStore ||
										o.store === currentStore._id)
							).length
						})`}
						{...a11yProps(0)}
					/>
					<Tab
						label={`Chờ TT (${
							orders.filter(
								(o) =>
									o.status === "PROCESSING" &&
									(!currentStore ||
										o.store === currentStore._id)
							).length
						})`}
						{...a11yProps(1)}
					/>
					<Tab
						label={`H.tất (${
							orders.filter(
								(o) =>
									o.status === "FINISHED" &&
									(!currentStore ||
										o.store === currentStore._id)
							).length
						})`}
						{...a11yProps(2)}
					/>
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={selectedTab}
				onChangeIndex={handleChangeTabIndex}
			>
				<TabPanel value={selectedTab} index={0} dir={theme.direction}>
					{orders
						.filter(
							(o) =>
								o.status === "INIT" &&
								(!currentStore || o.store === currentStore._id)
						)
						.map((o) => (
							<Order key={o._id} order={o} />
						))}
				</TabPanel>
				<TabPanel value={selectedTab} index={1} dir={theme.direction}>
					{orders
						.filter(
							(o) =>
								o.status === "PROCESSING" &&
								(!currentStore || o.store === currentStore._id)
						)
						.map((o) => (
							<Order key={o._id} order={o} />
						))}
				</TabPanel>
				<TabPanel value={selectedTab} index={2} dir={theme.direction}>
					{orders
						.filter(
							(o) =>
								o.status === "FINISHED" &&
								(!currentStore || o.store === currentStore._id)
						)
						.map((o) => (
							<Order key={o._id} order={o} />
						))}
				</TabPanel>
			</SwipeableViews>

			{(currentStore?._id || !roles.includes("ADMIN")) && (
				<div
					className="create-order"
					onClick={(e) => props.history.push("/orders/create")}
				>
					+
				</div>
			)}

			<PopupDiscount
				open={showPopupDiscount}
				onClose={hidePopupDiscount}
				order={orders.find((o) => o._id == processingOrderId)}
			/>

			<PopupRevenueToDay
				open={showPopupRevenueToday}
				onClose={(e) => setShowPopupRevenueToday(false)}
				orders={orders}
				stores={stores}
				key={stores.length}
			/>

			<Confirm
				open={showConfirmRemoveOrder}
				onClose={(e) => confirmRemoveOrder(e)}
				title="Bạn có chắc hủy đơn này?"
			/>

			{changeOrderTableName && (
				<PopupChangeOrderTableName
					open={changeOrderTableName}
					order={changeOrderTableName}
					onClose={(e) => onClosePopupChangeOrderTableName(e)}
				/>
			)}
		</div>
	);
};

const OrderListWithSocket = (props) => (
	<SocketContext.Consumer>
		{(socket) => <OrderList {...props} socket={socket} />}
	</SocketContext.Consumer>
);

export default withRouter(OrderListWithSocket);
