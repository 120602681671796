import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../services/httpService";
import PopupChooseProduct from "./popup/popupChooseProduct";
import PopupConfirmCreateOrder from "./popup/popupConfirmCreateOrder";
import { showMessage } from "../../redux/common/commonActions";
import { useDispatch } from "react-redux";
const OrderDetail = (props) => {
	const [order, setOrder] = useState({
		products: [],
		extraInfo: {
			tableName: "",
		},
		totalAmount: 0,
	});

	const [showPopupChooseProduct, setShowPopupChooseProduct] = useState(false);
	const [showPopupConfirmCreateOrder, setShowPopupConfirmCreateOrder] =
		useState(false);
	let saving = false;
	const dispatch = useDispatch();

	const {
		match: { params },
	} = props;

	useEffect(() => {
		if (+params.orderId) {
			getOrderDetail();
		} else {
			showPopup();
		}
	}, []);

	const getOrderDetail = async () => {
		const orderInDB = await HttpService.get(`/orders/${params.orderId}`);
		setOrder(orderInDB);
	};

	const save = async () => {
		// kiểm tra có sản phẩm thì mới tạo đơn hàng
		if (!order.products.length) return alert("Vui lòng chọn món!");

		if (saving) return;
		saving = true;

		order.totalAmount = getTotalAmount();
		if (!order._id) {
			return setShowPopupConfirmCreateOrder(true);
		} else {
			// xử lý vụ lên món
			if (
				order.status === "PROCESSING" &&
				order.tempProducts &&
				order.tempProducts.length > 0
			) {
				order.status = "INIT";
			}
			const data = await HttpService.put(`/orders/${order._id}`, order);
		}
		props.history.push("/orders");
	};

	const showPopup = () => {
		setShowPopupChooseProduct(true);
	};

	const hidePopup = () => {
		setShowPopupChooseProduct(false);
	};

	const addProduct = (products) => {
		products.forEach((p) => {
			let temp = order.products.find((i) => i._id === p._id);
			if (temp) {
				temp.quantity += p.quantity;
			} else {
				order.products.push(p);
			}

			// xử lý lên món thêm
			if (
				order.status === "PROCESSING" ||
				(order.status === "INIT" && order.tempProducts?.length > 0)
			) {
				!order.tempProducts && (order.tempProducts = []);
				let temp = order.tempProducts.find((i) => i._id === p._id);
				if (temp) {
					temp.quantity += p.quantity;
				} else {
					order.tempProducts.push(JSON.parse(JSON.stringify(p)));
				}
				console.log("order", order);
			}
		});
		// order.products = [...order.products, ...products]
		setOrder(order);
		// dispatch(showMessage('Đã thêm!', 'success', 1000))
		// setShowPopupChooseProduct(false)
	};

	const removeProductChoosed = (productIndex) => {
		order.products.splice(productIndex, 1);
		setOrder(JSON.parse(JSON.stringify(order)));
	};

	const Product = ({ product, productIndex, ...props }) => {
		return (
			<div key={product._id} className="dish-name">
				<strong> {product.name}</strong> (
				{window.formatMoney(product.price)} x {product.quantity})
				<span
					className="remove-product-choosed"
					onClick={(e) => removeProductChoosed(productIndex)}
				>
					&#10005;
				</span>
			</div>
		);
	};

	const getTotalAmount = () => {
		return order.products.reduce((a, b) => {
			a += b.price * (b.quantity || 1);
			return a;
		}, 0);
	};

	return (
		<div className="page-create-order">
			<div className="page-name">
				{order._id ? "Cập nhật đơn" : "Tạo đơn mới"}
			</div>
			<div className="choosed-products">
				{order.products.map((p, idx) => (
					<Product
						key={`${p._id}_${idx}`}
						product={p}
						productIndex={idx}
					/>
				))}
			</div>
			{order.products.length ? (
				<div
					style={{
						textAlign: "right",
						marginTop: 10,
						marginRight: 10,
					}}
				>
					Tổng tiền:{" "}
					<strong>{window.formatMoney(getTotalAmount())}</strong>
				</div>
			) : (
				<div className="no-product">Chưa chọn món nào cả?</div>
			)}
			<div className="text-center">
				<div
					className="btn btn-create-dish"
					onClick={(e) => showPopup()}
				>
					Chọn món mới
				</div>
			</div>
			{showPopupChooseProduct && (
				<PopupChooseProduct
					order={order}
					onPressCancel={(e) => hidePopup()}
					onPressOk={(p) => addProduct(p)}
				/>
			)}

			{order.products.length ? (
				<div className="actions-bottom">
					<div
						onClick={(e) => save()}
						className="btn btn-create-order"
					>
						{order._id ? "CẬP NHẬT" : "TẠO ĐƠN"}
					</div>
				</div>
			) : null}

			<PopupConfirmCreateOrder
				order={order}
				open={showPopupConfirmCreateOrder}
				onClose={(e) => setShowPopupConfirmCreateOrder(false)}
				{...props}
			/>
		</div>
	);
};

export default withRouter(OrderDetail);
