import React, { useEffect, useState } from 'react';
import { getListUser } from '../../redux';
import { HttpService } from '../../services/httpService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import md5 from 'md5'
import UserDetail from './userDetail';

const UserList = () => {
    const [users, setListUsers] = useState([])
    const [userPassword, setUserPassword] = useState({})
    const [isCreate, setIsCreate] = useState(false)
    useEffect(() => {    
        getListUsers()
    }, [])

    const getListUsers = async () => {
        const data = await HttpService.get('/users', {})
        if (data) {
            setListUsers(data)
        }
    }

    const onChangeStatus = async (e, userId) => {
        const params = {
            status: e.target.checked ? 'ACTIVE' : 'DISABLED'
        }
        const data = await HttpService.put(`/users/${userId}`, params)
        getListUsers()
    }

    const resetPassword = async userId => {
        const newPassword = `Coc${Math.random().toString(36)}`.slice(-8)
        const params = {
            password: md5(newPassword)
        }
        const data = await HttpService.put(`/users/${userId}`, params)
        setUserPassword({
            userId,
            newPassword
        })
    }

    const UserRow = ({ user, idx }) => {
        return <TableRow>
            <TableCell>{idx}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.nickName}</TableCell>
            <TableCell>
            <Switch
                checked={user.status === 'ACTIVE'}
                onChange={e => onChangeStatus(e, user._id)}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            </TableCell>
            <TableCell>
                {user._id == userPassword.userId ? userPassword.newPassword : <Button variant="contained" color="primary" onClick={e => resetPassword(user._id)}>
                    Reset
                </Button>}
            </TableCell>
        </TableRow>
    }

    const togglePopupCreate = (reload) => {
        setIsCreate(!isCreate)
        if (reload) getListUsers()
    }
    
    return (
        <div>
            <div className='page-name'>Quản lý người dùng</div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Tên đăng nhập</TableCell>
                            <TableCell>Tên</TableCell>
                            <TableCell>Vô hiệu hóa / Kích hoạt</TableCell>
                            <TableCell>Reset mật khẩu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((u, idx) => <UserRow key={u._id} user={u} idx={idx + 1} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {isCreate && <UserDetail onClose={togglePopupCreate}/>}
            <div className="btn-create-circle" onClick={togglePopupCreate}>
                +
            </div>
        </div>
    );
};

export default UserList;