import {SET_USER_INFO, LOGOUT, SET_CURRENT_STORE} from "./userTypes";
let currentStore = localStorage.getItem('currentStore') || null;
if (currentStore) {
    try {
        currentStore = JSON.parse(currentStore);
    } catch (err) {
        console.log(err);
    }
}

const initialState = {
    userInfo: null,
    currentStore
}

const UserReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload.userInfo
            }
        case SET_CURRENT_STORE:
            return {
                ...state,
                currentStore: payload.storeInfo
            }
        case LOGOUT:
            return {
                ...state,
                userInfo: null
            }
        default:
            return state
    }
}

export default UserReducer
