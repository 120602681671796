import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentStore} from "../../../redux";

const PopupRevenueToDay = ({open, onClose, orders, stores}) => {
    const [revenueToday, setRevenueToday] = useState(0)
    const [estimateRevenueToday, setEstimateRevenueToday] = useState(0)
    const [revenueTodayOfEachStore, setRevenueTodayOfEachStore] = useState([])
    const userInfo = useSelector(state => state.user.userInfo)
    const roles = userInfo && (userInfo.roles || []) || [];
    const dispatch = useDispatch();

    useEffect(() => {
        calculateRevenueToday();
        calculateRevenueTodayOfEachStore()
    }, [orders])

    const calculateRevenueToday = () => {
        // doanh thu hiện tại
        const revenue = orders.filter(o => o.status === 'FINISHED').reduce((a, b) => {
            a += b.totalAmount
            return a
        }, 0)
        setRevenueToday(revenue)

        // doanh thu dự kiến
        const estimateRevenue = orders.reduce((a, b) => {
            a += b.totalAmount
            return a
        }, 0)
        setEstimateRevenueToday(estimateRevenue)
    }

    const calculateRevenueTodayOfEachStore = () => {
        const revenueOfEachStore = stores.map(s => {
            // doanh thu hiện tại
            const revenue = orders.filter(o => o.store === s._id && o.status === 'FINISHED').reduce((a, b) => {
                a += b.totalAmount
                return a
            }, 0)

            // doanh thu dự kiến
            const estimateRevenue = orders.filter(o => o.store === s._id).reduce((a, b) => {
                a += b.totalAmount
                return a
            }, 0)

            return {
                storeId: s._id,
                revenue,
                estimateRevenue
            }
        })
        setRevenueTodayOfEachStore(revenueOfEachStore)
    }

    const setStore = store => {
        dispatch(setCurrentStore(store));
        onClose();
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle id="confirmation-dialog-title">Doanh thu hôm nay</DialogTitle>
            <DialogContent dividers>
                <div>
                    <div>Tổng đơn hàng: {orders.length}</div>
                    <div>Đã thanh toán: {orders.filter(o => o.status === 'FINISHED').length}</div>
                    <div>Chờ thanh toán: {orders.filter(o => o.status != 'FINISHED').length}</div>
                    <div>Doanh thu: <strong>{window.formatMoney(revenueToday)}</strong></div>
                    {(estimateRevenueToday != revenueToday) &&
                    <div>Doanh thu dự kiến: <strong>{window.formatMoney(estimateRevenueToday)}</strong></div>}
                    {roles.includes('ADMIN') && <div className="btn view-orders-store" onClick={e => setStore(null)}>Xem tất cả ĐH</div>}
                </div>

                {
                    roles.includes('ADMIN') &&
                    stores.map(s => {
                            const revenueOfStore = revenueTodayOfEachStore.find(r => r.storeId === s._id);
                            if (!revenueOfStore) return null;
                            return <div>
                                <hr/>
                                <div>Chi nhánh: <strong>{s.name}</strong></div>
                                <div>
                                    Tổng ĐH: {orders.filter(o => o.store === s._id).length} |
                                    Đã TT: {orders.filter(o => o.store === s._id && o.status === 'FINISHED').length} |
                                    Chờ TT: {orders.filter(o => o.store === s._id && o.status != 'FINISHED').length}
                                </div>
                                <div>Doanh thu: <strong>{window.formatMoney(revenueOfStore.revenue)}</strong></div>
                                {(revenueOfStore.estimateRevenue != revenueOfStore.revenue) &&
                                <div>Doanh thu dự kiến: <strong>{window.formatMoney(revenueOfStore.estimateRevenue)}</strong></div>}
                                <div className="btn view-orders-store" onClick={e => setStore(s)}>Xem ĐH {s.name}</div>
                            </div>
                        }
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={e => onClose()} color="primary">
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupRevenueToDay;
