import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { HttpService } from '../../services/httpService';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import IconExcel from '../../public/icons/excel-icon.svg'

const ReportRevenue = () => {
    const [reportRevenues, setReportRevenues] = useState([])
    const [filter, setFilter] = useState({
        fromDate: new Date(`${moment(new Date().setDate(1)).format('YYYY-MM-DD')}`),
        toDate: new Date()
    })

    useEffect(() => {
        getReportRevenues()
    }, [filter])

    const getReportRevenues = async () => {
        const queryParams = {
            fromDate: filter.fromDate.setHours(0, 0, 0),
            toDate: filter.toDate.setHours(23, 59, 59)
        }
        if (filter.export) {
            queryParams.export = true
        }
        
        const data = await HttpService.get('/reports/getReportRevenue', queryParams)
        if (data) setReportRevenues(data)
    }

    const ReportRevenueRow = ({ data }) => {
        return <TableRow>
            <TableCell component="th" scope="row">
                {data.name}
            </TableCell>
            <TableCell align="right">{window.formatMoney(data.totalAmount, '')}</TableCell>
            <TableCell align="right">{data.rate}</TableCell>
        </TableRow>
    }

    const handleDateChange = (date, field) => {
        if (field === 'fromDate') {
            setFilter({
                ...filter,
                fromDate: date,
                export: false
            });
        } else if (field == 'toDate') {
            setFilter({
                ...filter,
                toDate: date,
                export: false
            });
        }
    };

    const exportExcel = () => {
        setFilter({
            ...filter,
            export: true
        });
    }

    return (
        <div>
            <div className="page-name">Thống kê doanh thu <img src={IconExcel} className='page-name-icon excel-icon' onClick={e => exportExcel()}/></div>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                id="123"
                                margin="normal"
                                label="Từ ngày"
                                format="dd/MM/yyyy"
                                value={filter.fromDate}
                                onChange={date => handleDateChange(date, 'fromDate')}
                                KeyboardButtonProps={{
                                    'aria-label': 'Từ ngày',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                id="456"
                                margin="normal"
                                label="Đến ngày"
                                format="dd/MM/yyyy"
                                value={filter.toDate}
                                onChange={date => handleDateChange(date, 'toDate')}
                                KeyboardButtonProps={{
                                    'aria-label': 'Đến ngày',
                                }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider >
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Hạng mục</TableCell>
                            <TableCell align="right">Tổng sales (đ)</TableCell>
                            <TableCell align="right">Tỉ lệ (%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportRevenues.map(r => <ReportRevenueRow key={r.name} data={r} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ReportRevenue;